.link {
  text-decoration: none;
  color:inherit;
}


.nav-btn {
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  font-size: 20px;
}

@media only screen and (max-width: 1024px) {
  .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  .nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    background-color: whitesmoke;
    transition: 1s;
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
    opacity: 1;
    z-index: 5;
  }

  .responsive_nav {
    -webkit-transform: none;
            transform: none;
  }

  .nav-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }

}

.noDisplay {
  display: none;
  visibility: hidden
}


/* *** */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* basic styles */

.skeleton {
    background-color: #ddd;
    margin: 10px 0;
    border-radius: 4px;
}

.skeleton.text {
    width: 100%;
    height: 12px;
}

.skeleton.body {
    width: 100%;
    height: 100px;
}

.skeleton.title {
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
}

.skeleton.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.skeleton.thumbnail {
    width: 100px;
    height: 100px;
}

.skeleton-wrapper {
    margin: 20px 5px;
    padding: 10px 15px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

/* Skeleton Profile */
.skeleton-profile {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
    gap: 30px;
    align-items: center;
}

/* Themes */
.skeleton-wrapper.light {
    background: #f2f2f2;
}

.skeleton-wrapper.dark {
    background: #444;
}

.skeleton-wrapper.dark .skeleton{
    background: #777;
}

/* animation effects */
.shimmer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-animation: loading 2.5s infinite;
            animation: loading 2.5s infinite;
}

.shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    -webkit-transform: skewX(-20deg);
            transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05)
}

.dark .shimmer {
    background: rgba(255, 255, 255, 0.05);
}

@-webkit-keyframes loading {
    0% { -webkit-transform: translateX(-150%); transform: translateX(-150%) }
    50% { -webkit-transform: translateX(-60%); transform: translateX(-60%) }
    100% { -webkit-transform: translateX(150%); transform: translateX(150%) }
}

@keyframes loading {
    0% { -webkit-transform: translateX(-150%); transform: translateX(-150%) }
    50% { -webkit-transform: translateX(-60%); transform: translateX(-60%) }
    100% { -webkit-transform: translateX(150%); transform: translateX(150%) }
}
