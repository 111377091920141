.link {
  text-decoration: none;
  color:inherit;
}


.nav-btn {
  padding: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  font-size: 20px;
}

@media only screen and (max-width: 1024px) {
  .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  .nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 20px;
    background-color: whitesmoke;
    transition: 1s;
    transform: translateY(-100vh);
    opacity: 1;
    z-index: 5;
  }

  .responsive_nav {
    transform: none;
  }

  .nav-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }

}

.noDisplay {
  display: none;
  visibility: hidden
}


/* *** */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
